@import '../../../../scss/theme-bootstrap';

.product-brief-v2 {
  background-color: $color--white;
  height: 100%;
  margin: 0 auto;
  width: 100%;
  max-width: 390px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include breakpoint($bp--medium-up) {
    padding: 0;
  }
  &[data-sku-inv-status='3'] {
    .product-brief__cta {
      &:hover {
        display: inline;
      }
    }
  }
  .product-brief {
    &__container {
      border: 1px solid $color--gray--lighter;
      width: 100%;
    }
    &__top {
      height: 20px;
    }
    &__image {
      &-img {
        max-height: 320px;
        @include breakpoint($bp--xlarge-up) {
          max-height: 290px;
        }
        @include breakpoint($bp--xxlarge-up) {
          max-height: 320px;
        }
        &--alt-image {
          opacity: 0;
          visibility: hidden;
          position: absolute;
          #{$ldirection}: 3.5%;
          top: 0;
        }
      }
    }
    &__early-access {
      font-size: 11px;
      font-family: $helvetica-font;
      color: $color--black;
      padding-top: 3px;
      text-transform: lowercase;
      .early-access {
        &__brand-name {
          text-transform: uppercase;
        }
      }
    }
    &__shades {
      min-height: 10px;
      @include breakpoint($bp--medium-up) {
        min-height: 25px;
      }
      @include breakpoint($bp--xxlarge-up) {
        min-height: 30px;
      }
      .product-brief-shades {
        min-height: 50px;
        @include breakpoint($bp--medium-up) {
          min-height: 25px;
        }
        @include breakpoint($bp--xxlarge-up) {
          min-height: 30px;
        }
        .slick-prev,
        .slick-next {
          display: block;
          &.disable-arrow {
            display: none;
          }
        }
        @if $cr21_mpp == false {
          .slick-prev {
            transform: rotate(-90deg);
          }
          .slick-next {
            transform: rotate(90deg);
          }
          .slick-prev,
          .slick-next {
            @include breakpoint($bp--medium-up) {
              width: 18px;
              height: 18px;
              top: 4px;
            }
            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
        @if $cr21 == false {
          .brief-product-arrows {
            position: relative;
            .slick-prev,
            .slick-next {
              transform: none;
              top: -23px;
              min-height: 18px;
              height: 20px;
              width: 30px;
              line-height: unset;
              font-size: unset;
              @include breakpoint($bp--medium-up) {
                transform: none;
                top: -12px;
                height: 18px;
              }
              .icon {
                vertical-align: initial;
              }
            }
            .slick-next {
              #{$rdirection}: 0;
              [dir='rtl'] & {
                #{$ldirection}: 0;
              }
            }
            .slick-prev {
              #{$ldirection}: 0;
              [dir='rtl'] & {
                #{$ldirection}: auto;
                #{$rdirection}: 0;
              }
            }
          }
        }
      }
    }
    a:hover {
      .product-brief__image-img {
        opacity: 1;
        visibility: visible;
      }
    }
    &__badge {
      #{$rdirection}: 1em;
      #{$ldirection}: auto;
      color: $color--black;
      top: 10px;
      @if $fonts_update == true {
        font-family: $helvetica-font;
        font-size: 15px;
        @include breakpoint($bp--xlarge-up) {
          font-size: 11px;
          margin: 0;
        }
      }
    }
    &__rating {
      #{$ldirection}: 15px;
      display: table;
      top: 10px;
      z-index: 1;
      @if $cr21_mpp == false {
        width: 45%;
      }
      &-count {
        color: $color--black;
        @if $fonts_update == true {
          @include h6();
          font-family: $helvetica-font;
          @include breakpoint($bp--xlarge-up) {
            font-size: 11px;
            margin: 0;
          }
        }
      }
      .product__rating {
        display: flex;
        .product__rating-percentage {
          #{$ldirection}: 0;
        }
      }
      .product-brief__rating-total {
        @if $cr21_mpp == false {
          @include swap_direction(padding, 0 0 0 0.5em);
        }
        margin-top: 1px;
        @include breakpoint($bp--medium-up) {
          margin-top: 3px;
        }
      }
      &-count {
        &:before {
          content: '(';
        }
        &:after {
          content: ')';
        }
      }
      &-copy {
        color: $color--black;
        margin-#{$ldirection}: -5px;
      }
    }
    &__details {
      min-height: 130px;
    }
    &__name-link {
      margin-top: 2px;
      width: 100%;
    }
    &__shade {
      margin-bottom: 2px;
    }
    &__product-name {
      color: $color--black;
      font-family: $ano-bold_regular-font;
      font-size: 18px;
      letter-spacing: 0.02px;
      line-height: 22px;
      min-width: 100%;
      @if $fonts_update == true {
        @include h4();
        line-height: 22px;
        margin: 0;
      }
    }
    &__description {
      color: $color--black;
      font-family: $roboto-mono_regular-font;
      margin-bottom: 5px;
      cursor: pointer;
      text-transform: none;
      width: 100%;
      letter-spacing: -0.1px;
      line-height: 19px;
      font-size: 15px;
      margin-top: -3px;
      @if $fonts_update == true {
        font-family: $helvetica-font;
        letter-spacing: 0;
        line-height: 24px;
        margin: 0;
      }
    }
    &__description::first-letter {
      text-transform: uppercase;
    }
    &__price {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      width: 100%;
      float: none;
      margin-top: -8px;
      font-family: $ano-bold_regular-font;
      color: $color--black;
      letter-spacing: 0.02px;
      line-height: 17px;
      font-size: 15px;
      @if $fonts_update == true {
        @include h5();
        line-height: 17px;
        @include breakpoint($bp--xlarge-up) {
          font-size: 15px;
        }
      }
      .product-price-v1 {
        float: none;
        align-self: flex-start;
      }
      .product-price-installment {
        font-family: $roboto-mono_regular-font;
        font-size: 13px;
      }
      &-details {
        margin-top: 18px;
      }
      .product-price {
        @if $fonts_update == true {
          @include h5();
          @include breakpoint($bp--xlarge-up) {
            font-size: 15px;
          }
        }
      }
    }
    &__product-size {
      display: inline-block;
      font-size: 10px;
    }
    &__cta {
      border: none;
      display: inline-block;
      position: relative;
      top: unset;
      #{$ldirection}: 0;
      transform: none;
      opacity: 1;
      visibility: visible;
      width: 100%;
      line-height: 22px;
      letter-spacing: 0.24px;
      .product-add-to-bag,
      .product-brief-cta,
      .product-inventory-status--button,
      .product-vto-link {
        background-color: $color--black;
        height: 51px;
        line-height: 50px;
        padding: 0;
        text-transform: uppercase;
        font-size: 18px;
        display: block;
        color: $color--white;
        font-family: $ano-bold_regular-font;
        width: 190px;
        @if $fonts_update == true {
          @include h6();
          font-size: 15px;
          line-height: 50px;
        }
        max-width: 49%;
        float: #{$ldirection};
      }
      .product-vto-link {
        float: #{$rdirection};
        position: relative;
        margin-#{$ldirection}: 2%;
        .cta-vto-link {
          display: inherit;
          border: 1px solid black;
          #{$ldirection}: 0;
          top: 0;
          width: 100%;
          height: 100%;
          line-height: 50px;
          background-color: $color--white;
          text-decoration: none;
          &:hover {
            color: $color--black;
          }
        }
        &.product-foundation-vto-link {
          @include breakpoint($bp--medium-up) {
            display: none;
          }
        }
      }
      .product-brief__inventory-status {
        clear: #{$ldirection};
        padding-top: 1px;
      }
      .product-inventory-status--button {
        background-color: $color--gray--dusty;
        &.notify-display--soldout-139 {
          display: none;
        }
      }
      .product-inventory-status__sold-out-notify {
        &.notify-display--soldout-139 {
          background-color: transparent;
        }
        .product-inventory-status--button {
          background-color: $color--black;
        }
      }
      .product-brief-cta .product-brief__inventory-status {
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
    &__ppu,
    &__ppu-price {
      font-size: 13px;
      line-height: 14px;
      color: $color--gray--lighter;
      text-align: #{$ldirection};
      @if $fonts_update == false {
        font-family: $roboto-mono_regular-font;
      }
    }
    &__ppu-price {
      padding-top: 5px;
    }
    &__ppu {
      @if $cr21_mpp == true {
        display: none;
      }
    }
    &__footer {
      margin-top: 3px;
      display: flex;
      flex-direction: column;
      flex: 1;
      @include breakpoint($bp--medium-up) {
        margin-top: -18px;
      }
    }
  }
}

.selected {
  color: $color--black;
  border-color: $color--black;
}

.grid--mpp {
  &.active-model {
    .product-brief {
      &__top {
        height: 0;
      }
      &__image-img {
        width: 100%;
        height: auto;
      }
      &__badge,
      &__rating-count {
        color: $color--white;
      }
      &__rating {
        .product__rating {
          &-stars {
            background-image: none;
            .opaque {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
  &:not(.active-model) {
    .product-brief {
      &__image {
        &.has-alt-image:hover {
          .product-brief__image-img {
            @include breakpoint($bp--xlarge-up) {
              opacity: 0;
              visibility: hidden;
            }
            &--alt-image {
              @include breakpoint($bp--xlarge-up) {
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }
      }
      &__rating {
        .product__rating {
          &-stars {
            .opaque {
              background-image: url('/sites/maccosmetics/themes/mac_base/images/icons/src/stars_reviews_0_stars.svg');
              background-repeat: no-repeat;
            }
            &-rating {
              background-image: url('/sites/maccosmetics/themes/mac_base/images/icons/src/stars_reviews_5_stars.svg');
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
  }
}
